<template>
  <div>
    <div
      id="WhereWeAreMap"
      v-for="(mapData, index) in mapData"
      :key="'mapData' + index"
      preserveAspectRatio="xMidYMid slice"
    >
      <img v-if="mapData.mapBg" :src="mapData.mapBg" class="mapBg" />
      <div class="direction">
        <component :is="mapData.mapLine"></component>
      </div>
      <div
        class="right-side"
        :style="{ 'background-image': 'url(' + mapData.venueBg + ')' }"
      >
        <div class="locations">
          <div class="locations__left">
            <h2 :class="{ singleLineVenue: mapData.singleLineVenue }">
              {{ mapData.venue }}
            </h2>
            <h2>Allegiant Stadium</h2>
          </div>
          <div
            class="locations__right"
            :class="{
              singleLineVenue: mapData.singleLineVenue,
              threeLineVenue: mapData.threeLineVenue,
            }"
          >
            <div class="locations__right--pin"></div>
            <div
              class="locations__right--line"
              :class="{ threeLineVenue: mapData.threeLineVenue }"
            ></div>
            <div
              class="locations__right--circle"
              :class="{ threeLineVenue: mapData.threeLineVenue }"
            ></div>
          </div>
        </div>
        <div class="miles">
          <div class="miles__num">
            <h2>{{ mapData.distance }}</h2>
          </div>
          <div class="miles__word">
            <h3>Miles</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaesarsPalacePath from "@/components/sections/Venue/WhereWeAre/Paths/CaesarsPalacePath";
import BellagioPath from "@/components/sections/Venue/WhereWeAre/Paths/BellagioPath";
import EncoreWynnPath from "@/components/sections/Venue/WhereWeAre/Paths/EncoreWynnPath";
import LasVegasConventionCenterPath from "@/components/sections/Venue/WhereWeAre/Paths/LasVegasConventionCenterPath";
import LuxorPath from "@/components/sections/Venue/WhereWeAre/Paths/LuxorPath";
import MResortSpaCasinoPath from "@/components/sections/Venue/WhereWeAre/Paths/MResortSpaCasinoPath";
import MandalayBayPath from "@/components/sections/Venue/WhereWeAre/Paths/MandalayBayPath";
import MGMPath from "@/components/sections/Venue/WhereWeAre/Paths/MGMPath";
import NewYorkPath from "@/components/sections/Venue/WhereWeAre/Paths/NewYorkPath";
import ParkMGMPath from "@/components/sections/Venue/WhereWeAre/Paths/ParkMGMPath";
import StratospherePath from "@/components/sections/Venue/WhereWeAre/Paths/StratospherePath";
import VenetianPath from "@/components/sections/Venue/WhereWeAre/Paths/VenetianPath";
import LasVegasStripPath from "@/components/sections/Venue/WhereWeAre/Paths/LasVegasStripPath";
import AriaPath from "@/components/sections/Venue/WhereWeAre/Paths/AriaPath";
import ResortWorldPath from "@/components/sections/Venue/WhereWeAre/Paths/ResortWorldPath";
import ParisPath from "@/components/sections/Venue/WhereWeAre/Paths/ParisPath";
import HarrahsPath from "@/components/sections/Venue/WhereWeAre/Paths/HarrahsPath";
import FontainebleauPath from "@/components/sections/Venue/WhereWeAre/Paths/FontainebleauPath";
import CosmopolitanPath from "@/components/sections/Venue/WhereWeAre/Paths/CosmopolitanPath";
import FourSeasonsPath from "@/components/sections/Venue/WhereWeAre/Paths/FourSeasonsPath";
import WaldorfAstoriaPath from "@/components/sections/Venue/WhereWeAre/Paths/WaldorfAstoriaPath";
import CaesarsForumPath from "@/components/sections/Venue/WhereWeAre/Paths/CaesarsForumPath";
import DurangoPath from "@/components/sections/Venue/WhereWeAre/Paths/DurangoPath";
import RedRockPath from "@/components/sections/Venue/WhereWeAre/Paths/RedRockPath";
import GreenValleyRanchPath from "@/components/sections/Venue/WhereWeAre/Paths/GreenValleyRanchPath";

export default {
  name: "WhereWeAreMap",
  components: {
    CaesarsPalacePath,
    BellagioPath,
    EncoreWynnPath,
    LasVegasConventionCenterPath,
    LuxorPath,
    MResortSpaCasinoPath,
    MandalayBayPath,
    MGMPath,
    NewYorkPath,
    ParkMGMPath,
    StratospherePath,
    VenetianPath,
    LasVegasStripPath,
    AriaPath,
    ResortWorldPath,
    ParisPath,
    HarrahsPath,
    FontainebleauPath,
    CosmopolitanPath,
    FourSeasonsPath,
    WaldorfAstoriaPath,
    CaesarsForumPath,
    DurangoPath,
    RedRockPath,
    GreenValleyRanchPath,
  },
  props: {
    mapData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#WhereWeAreMap {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 1600px;
  .direction {
    position: absolute;
    overflow: hidden;
    //max-width: 1600px;
    height: 100vh;
    width: 100vw;
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      //filter: drop-shadow(0 0 7px #E5FFC9);
    }
    img {
      max-width: 1600px;
      width: 1600px;
      height: 900px;
      position: relative;
      top: 0px;
      left: 0px;
    }
  }
  .right-side {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30%;
    min-width: 420px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    animation: animateRight 1s ease-in-out 0s 1;
    animation-fill-mode: forwards;

    .locations {
      position: absolute;
      bottom: 200px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      &__left {
        text-align: right;
        width: 240px;
        margin-right: 100px;
        h2 {
          font-family: "ArtegraSans-Bold";
          text-transform: uppercase;
          text-align: right;
          font-size: 29px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 25px;
          &.singleLineVenue {
            position: relative;
            top: -10px;
          }
          &:first-child {
            margin-bottom: 75px;
          }
        }
      }
      &__right {
        width: 20%;
        position: absolute;
        right: 0;
        top: 0;
        &.singleLineVenue {
          top: -25px;
        }
        &.threeLineVenue {
          top: 10px;
        }
        &--pin {
          position: absolute;
          top: 14px;
          right: 40px;
          margin-left: 115px;
          background-color: #fff;
          border-radius: 50%;
          border: 10px solid #fff;
          width: 10px;
          height: 10px;
          box-shadow: 0 0 5px 2px #fff;
          &::after {
            position: absolute;
            content: "";
            width: 0px;
            height: 0px;
            bottom: -29px;
            left: -10px;
            border: 10px solid transparent;
            border-top: 17px solid #fff;
          }
        }
        &--line {
          position: absolute;
          display: block;
          top: 57px;
          right: 49px;
          width: 2px;
          height: 74px;
          background-color: #fff;
          box-shadow: 0 0 5px 2px #fff;
          &.threeLineVenue {
            height: 88px;
          }
        }
        &--circle {
          position: absolute;
          display: block;
          top: 145px;
          right: 45px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.5);
          &.threeLineVenue {
            top: 160px;
          }
        }
      }
    }
    .miles {
      position: absolute;
      bottom: 65px;
      right: 0;
      display: flex;
      &__num {
        background-image: linear-gradient(180deg, #e2e2e2 0%, #c0c0c0 100%);
        height: 99px;
        width: 190px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        display: flex;
        justify-content: center;
        transform: skew(-25deg, 0deg);
        z-index: 1;
        margin-right: -25px;
        h2 {
          font-family: "ArtegraSans-Light";
          font-size: 54px;
          color: #000000;
          letter-spacing: -0.65px;
          text-align: center;
          padding: 17px 25px 0 0;
          transform: skew(25deg, 0deg);
        }
      }
      &__word {
        background-image: linear-gradient(180deg, #eeeeee 0%, #d8d8d8 100%);
        height: 99px;
        width: 175px;
        z-index: 2;
        h3 {
          font-family: "ArtegraSans-Bold";
          text-transform: uppercase;
          font-size: 23px;
          color: #000000;
          letter-spacing: 0;
          line-height: 23px;
          padding: 37px 0 0 25px;
        }
      }
    }
  }
  .mapBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @keyframes animateRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
</style>
